import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

html {
    height: 100%;
    font-size: 3pt;
    @media screen and (min-width: 400px) {
        font-size: 1vw;
    }
}

:root {

    --p: 1rem;
    --h1: 22px;
    --h2: 20px;
    --h3: 19px;
    --h4: 18px;
    --h5: 17px;
    --h6: 16px;
    --primaryFont: 'Inter', sans-serif;
    --primaryColor: #B27746;
    --secondaryColor: #C4895C;
    --primaryBgColor: #0C1C26;
    --secondaryBgColor: #142E38;
    --footerBgColor: #C61438;

    body {        
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;

        padding: 0;
        margin: 0;
        background-color: var(--primaryBgColor);
        color: var(--primaryColor);
        overflow-x: hidden;
        height: 100%;

        .home-banner {
            .infoContainer__principal{
                padding-bottom: 35% !important;

                @media screen and (min-width: 768px) {
                    padding-bottom: 10% !important;
                }
            }
        }
    }

    .page-container {
        padding-left: 55px;
        padding-right: 55px;

        @media screen and (min-width: 768px) {
            padding-left: 78px;
            padding-right: 78px;
        }
    }
}

#gatsby-focus-wrapper{
    height: 100%;
}

#___gatsby {
    height: 100%;
}

`