exports.components = {
  "component---src-pages-export-404-tsx": () => import("./../../../src/pages/export/404.tsx" /* webpackChunkName: "component---src-pages-export-404-tsx" */),
  "component---src-pages-export-company-tsx": () => import("./../../../src/pages/export/company.tsx" /* webpackChunkName: "component---src-pages-export-company-tsx" */),
  "component---src-pages-export-contact-tsx": () => import("./../../../src/pages/export/contact.tsx" /* webpackChunkName: "component---src-pages-export-contact-tsx" */),
  "component---src-pages-export-home-tsx": () => import("./../../../src/pages/export/home.tsx" /* webpackChunkName: "component---src-pages-export-home-tsx" */),
  "component---src-pages-export-products-cooked-tsx": () => import("./../../../src/pages/export/products/cooked.tsx" /* webpackChunkName: "component---src-pages-export-products-cooked-tsx" */),
  "component---src-pages-export-products-raw-tsx": () => import("./../../../src/pages/export/products/raw.tsx" /* webpackChunkName: "component---src-pages-export-products-raw-tsx" */),
  "component---src-pages-export-products-retail-tsx": () => import("./../../../src/pages/export/products/retail.tsx" /* webpackChunkName: "component---src-pages-export-products-retail-tsx" */),
  "component---src-pages-export-products-semicooked-tsx": () => import("./../../../src/pages/export/products/semicooked.tsx" /* webpackChunkName: "component---src-pages-export-products-semicooked-tsx" */),
  "component---src-pages-export-recipes-tsx": () => import("./../../../src/pages/export/recipes.tsx" /* webpackChunkName: "component---src-pages-export-recipes-tsx" */),
  "component---src-pages-index-styles-tsx": () => import("./../../../src/pages/indexStyles.tsx" /* webpackChunkName: "component---src-pages-index-styles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-national-404-tsx": () => import("./../../../src/pages/national/404.tsx" /* webpackChunkName: "component---src-pages-national-404-tsx" */),
  "component---src-pages-national-catering-tsx": () => import("./../../../src/pages/national/catering.tsx" /* webpackChunkName: "component---src-pages-national-catering-tsx" */),
  "component---src-pages-national-contacto-tsx": () => import("./../../../src/pages/national/contacto.tsx" /* webpackChunkName: "component---src-pages-national-contacto-tsx" */),
  "component---src-pages-national-inicio-tsx": () => import("./../../../src/pages/national/inicio.tsx" /* webpackChunkName: "component---src-pages-national-inicio-tsx" */),
  "component---src-pages-national-nosotros-tsx": () => import("./../../../src/pages/national/nosotros.tsx" /* webpackChunkName: "component---src-pages-national-nosotros-tsx" */),
  "component---src-pages-national-productos-tsx": () => import("./../../../src/pages/national/productos.tsx" /* webpackChunkName: "component---src-pages-national-productos-tsx" */),
  "component---src-pages-national-recetas-tsx": () => import("./../../../src/pages/national/recetas.tsx" /* webpackChunkName: "component---src-pages-national-recetas-tsx" */)
}

